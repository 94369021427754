import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import LandingBanner from '../components/LandingBanner/LandingBanner';

const TermsPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <LandingBanner title="Terms &amp; Conditions" alt />
      <section className="internal-page__section">
        <p>
          Terms of Use W&amp;W Asset Management DAC (the &quot;Manager&quot;) is authorised and
          regulated by the Central Bank of Ireland under the European Communities (Undertakings for
          Collective Investment in Transferable Securities) Regulations 2011 and the European
          Communities (Alternative Investment Fund Managers Directive) Regulations 2013. The
          Manager&#39;s registered office is 7 th Floor, 5 Earlsfort Terrace, Dublin 2 and company
          registration number is 306156. The information contained in this website (the
          &quot;Website&quot;) is for information purposes only and does not take into account the
          particular investment objectives, financial conditions, or needs of specific investors and
          is not to be considered a personal recommendation or investment advice.​
        </p>
        <p>
          Please note that the value of investments and level of derived income can fall as well as
          rise and you may not get back the amount originally invested. Income actually received may
          vary from the amount of income projected at the time of making the investment. Past
          performance is not indicative or a guarantee of future performance. Changes in exchange
          rates may also cause an investment to fluctuate in value. Levels of taxation depend on an
          investor&#39;s individual circumstances and the value of any applicable tax reliefs. We do
          not provide tax, accounting, or legal advice to our clients, and all investors are advised
          to consult with their tax, accounting, or legal advisers regarding any potential
          investment. The information and any opinions set out on this Website have been obtained
          from sources that we consider reliable, but we do not represent such information and
          opinions to be accurate or complete, and thus should not be relied upon as such.
        </p>
        <p>
          In accordance with regulatory requirements certain telephone or other electronic
          communications with the Manager may be recorded. Copies of such records will be treated in
          accordance with the Manager&#39;s data privacy policies and will be available on request
          for a period of at least five years from the date of the communication. Please note that
          records may be stored or otherwise processed in countries other than the country in which
          you are located.
        </p>
        <p>
          The Manager does not guarantee that this Website is secure, accurate or complete as
          information could be intercepted, tampered with, corrupted, lost, destroyed or delayed or
          could contain viruses, malware or any other form of nefarious or corrupted code. Although
          this Website is believed to be free of any virus or other defect that might affect any
          computer system into which they are received and opened, it is the responsibility of the
          user to ensure that they are virus free. By corresponding electronically with the Manager,
          you understand and accept all risks associated with electronic communications. You should
          note that there is no guarantee that we will receive emails you send. Important or urgent
          messages should be sent by registered mail or communicated by telephone.
        </p>
        <p>
          Direct Marketing:
          <br /> If you do not wish to receive any further information from the Manager in relation
          to products or services that may be of interest to you please email us at
          amd@ww-dublin.com to unsubscribe.
        </p>
      </section>
    </Layout>
  );
};

export default TermsPage;
